<template>
  <b-row class="no-gutters">
    <b-col cols="12" class="picture-main-wrapper">
      <div v-if="slider.items.length > 1">
        <a
          href="#"
          class="custom-carousel-controls carousel-control-next"
          @click.prevent="miniSliderControl('next')"
        >
          <img src="@/assets/icons/arrow.svg" class="arrow" />
        </a>
        <a
          href="#"
          class="custom-carousel-controls carousel-control-prev"
          @click.prevent="miniSliderControl('prev')"
        >
          <img src="@/assets/icons/arrow.svg" class="arrow" />
        </a>
      </div>
      <AudioButton
        v-if="canShowSoundBtn"
        :audioFlag="sliderMuteVideo"
        @clicked="sliderAudioControl('slider')"
      />
      <b-carousel
        v-model="slider.current"
        v-observe-visibility="
          (isVisible, entry) => startGallery(isVisible, entry)
        "
        :ref="'miniSlider' + uniqueIndex"
        :controls="false"
        :interval="slider.interval"
        class="ynv-core-carousel"
        fade
        img-height="400"
        img-width="650"
        @input="sliderChangeState()"
        @sliding-end="slideEnd()"
      >
        <b-carousel-slide
          v-for="(media, k) in slider.items"
          :key="k"
          @click="showModalGallery(media.scope)"
        >
          <template v-slot:img>
            <template v-if="media.scope == 'panorama'">
              <div
                class="click-trigger"
                @click="showModalGallery(media.scope)"
              ></div>
              <b-embed
                class="video-item"
                type="iframe"
                :aspect="videoRatio"
                :src="getVal(media.public_url)"
              ></b-embed>
            </template>
            <template
              v-else-if="
                media.scope == 'video' ||
                (media.type == 'video' && media.scope == 'gallery')
              "
            >
              <div
                v-if="videoModal"
                class="click-trigger"
                @click="showModalGallery(media.scope)"
              ></div>
              <div
                class="video-background2 embed-responsive embed-responsive-4by3"
              >
                <YoutubePlayer
                  v-if="media.provider === 'youtube'"
                  :player-id="`slider_youtube${media.public_id}${media.uniqueId}`"
                  :ref="`slider_youtube${media.public_id}${media.uniqueId}`"
                  :videoId="media.public_id"
                  :loop="1"
                  :autoplay="1"
                  class="embed-responsive-item"
                />
                <VideoPlayer
                  v-else-if="media.provider === 'cdn'"
                  :public_url="media.public_url"
                  :options="options"
                  :ref="`slider_cdn${media.public_id}${media.uniqueId}`"
                />
                <VimeoVideo
                  v-else-if="media.provider === 'vimeo'"
                  :ref="`slider_vimeo${media.public_id}${media.uniqueId}`"
                  :public_id="media.public_id"
                  :options="options"
                />
              </div>
            </template>
            <template v-else>
              <div
                class="carousel-image-container embed-responsive embed-responsive-4by3"
              >
                <div
                  @click="showModalGallery(media.scope)"
                  class="carousel-image embed-responsive-item"
                  :class="[{ 'no-rounded-border': noRoundedBorder }]"
                  :style="{
                    'background-image':
                      'url(' +
                      buildMediaURL(
                        media.public_url,
                        media.provider,
                        imageResolution
                      ) +
                      ')',
                    cursor: 'pointer'
                  }"
                ></div>
              </div>
            </template>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <b-modal
        :id="'lightbox' + uniqueIndex"
        modal-class="lightbox"
        hide-header
        centered
        size="xl"
        @hidden="closePreviewModalOutside()"
      >
        <div class="closeModal">
          <button @click="closePreviewModal()">
            {{ $t("core.close_modal") }}
            <b-icon class="closeIcon" icon="x" />
          </button>
        </div>
        <AudioButton
          v-if="canShowPreviewSoundBtn"
          :audioFlag="sliderPreviewMuteVideo"
          @clicked="sliderAudioControl('preview')"
        />
        <b-carousel
          :interval="0"
          :controls="slider.items.length > 1"
          class="ynv-core-carousel"
          fade
          v-model="slider.index"
          @sliding-end="previewSlideEnd()"
        >
          <b-carousel-slide
            v-for="(media, i) in slider.items"
            :key="i"
            :class="{
              'video-item': media.scope == 'video'
            }"
          >
            <template v-slot:img>
              <template v-if="media.scope == 'panorama'">
                <b-embed
                  class="video-item"
                  type="iframe"
                  :aspect="videoRatio"
                  :src="getVal(media.public_url)"
                >
                </b-embed>
              </template>
              <template v-else-if="media.scope == 'video'">
                <div
                  v-if="media.provider === 'vimeo'"
                  class="embed-responsive embed-responsive-4by3"
                >
                  <VimeoVideo
                    :ref="`slider_preview_vimeo${media.public_id}`"
                    :public_id="media.public_id"
                    :options="options"
                  />
                </div>
                <div
                  v-if="media.provider === 'youtube'"
                  class="embed-responsive embed-responsive-4by3"
                >
                  <YoutubePlayer
                    :player-id="`slider_preview_youtube${media.public_id}`"
                    :ref="`slider_preview_youtube${media.public_id}`"
                    :videoId="media.public_id"
                    :loop="1"
                    :autoplay="1"
                    class="embed-responsive-item"
                  />
                </div>
                <div
                  v-if="media.provider === 'cdn'"
                  class="embed-responsive embed-responsive-4by3"
                >
                  <VideoPlayer
                    :public_url="media.public_url"
                    :options="options"
                    :ref="`slider_preview_cdn${media.public_id}`"
                  />
                </div>
              </template>
              <template v-else>
                <div class="preview-dummy-img">
                  <div
                    class="img-container"
                    :style="
                      'background-image: url(' +
                      getVal(media.public_url) +
                      (media.provider == 'cdn' ? '?d=50x50' : '&w=50x50') +
                      ')'
                    "
                  ></div>
                  <div
                    style="
                      position: absolute;
                      left: 49%;
                      top: 49%;
                      padding: 8px 8px 6px;
                      border-radius: 9px;
                      margin-top: -24px;
                      margin-left: -24px;
                      background: white;
                      box-shadow: rgb(169 169 169) 0px 0px 10px;
                    "
                  >
                    <div
                      class="spinner-border text-secondary gallery-loading"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="text-center bg-image">
                  <b-img
                    :src="
                      buildMediaURL(
                        media.public_url,
                        media.provider,
                        returnImgWidth()
                      )
                    "
                    class="img-fluid"
                  />
                </div>
              </template>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import YoutubePlayer from "@/components/youtubePlayer/YoutubePlayer";
import AudioButton from "@/components/AudioButton.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import VimeoVideo from "@/components/VimeoVideo.vue";
import { uniqueId } from 'lodash';
import { usePlayer } from "@/components/youtubePlayer/usePlayer";

export default {
  name: "MiniSlider",
  mixins: [lpMixins],
  components: {
    VimeoVideo,
    VideoPlayer,
    YoutubePlayer,
    AudioButton
  },
  props: {
    mediaList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    currentIndex: {
      type: Object,
      default: function () {
        return { index: 0, interval: 0 };
      }
    },
    imageResolution: {
      type: String,
      default: "520x340"
    },
    uniqueIndex: {
      type: [String, Number],
      default: "idx"
    },
    aspect: {
      type: String,
      default: "4:3"
    },
    videoRatio: {
      type: String,
      default: "3by1"
    },
    videoModal: {
      type: Boolean,
      default: true
    },
    noRoundedBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      usePlayer,
      slider: {
        interval: 0,
        items: [],
        index: 0,
        current: 0,
        start: true
      },
      sliderMuteVideo: true,
      sliderPreviewMuteVideo: true,
      options: {
        muted: true,
        loop: true,
        preload: true,
        playsinline: true,
        controls: false
      },
      prevSliderIndex: 0,
      prevPreviewSliderIndex: 0
    };
  },
  created() {
    this.slider.items = this.mediaList.map(media => {
      return {...media, uniqueId: uniqueId()};
    })
  },
  watch: {
    currentIndex(val) {
      this.slider.current = val.index;
    },
    "slider.current"(newVal, oldVal) {
      this.prevSliderIndex = oldVal;
    },
    "slider.index"(newVal, oldVal) {
      this.prevPreviewSliderIndex = oldVal;
    }
  },
  computed: {
    mute() {
      return this.sliderMuteVideo ? "none" : "block";
    },
    unmute() {
      return !this.sliderMuteVideo ? "none" : "block";
    },
    currentSlide() {
      return this.slider.items[this.slider.current];
    },
    canShowSoundBtn(){
      return this.currentSlide?.type === 'video' && [null,1].includes(this.currentSlide?.has_audio)
    },
    canShowPreviewSoundBtn(){
      return this.previewCurrentSlide?.type === 'video' && [null,1].includes(this.previewCurrentSlide?.has_audio)
    },
    previewCurrentSlide() {
      return this.slider.items[this.slider.index];
    },
    currentSlideRef() {
      return (
        "slider_" + this.currentSlide?.provider + this.currentSlide?.public_id + this.currentSlide.uniqueId
      );
    },
    currentSliderPreviewRef() {
      return (
        "slider_preview_" +
        this.previewCurrentSlide?.provider +
        this.previewCurrentSlide?.public_id
      );
    },
    player() {
      return this.usePlayer().players[this.currentSlideRef] || this.$refs[this.currentSlideRef][0].player.player;
    },
    previewPlayer() {
      return this.usePlayer().players[this.currentSliderPreviewRef] || this.$refs[this.currentSliderPreviewRef][0].player.player;
    }
  },
  methods: {
    sliderAudioControl(type = "slider") {
      if (type === "slider") {
        this.sliderMuteVideo = !this.sliderMuteVideo;
        this.sliderAudioSettings(
          this.player,
          this.currentSlide.provider,
          this.sliderMuteVideo
        );
      } else {
        this.sliderPreviewMuteVideo = !this.sliderPreviewMuteVideo;
        this.sliderAudioSettings(
          this.previewPlayer,
          this.previewCurrentSlide.provider,
          this.sliderPreviewMuteVideo
        );
      }
    },
    sliderAudioSettings(player, provider, flag) {
      if (provider === "cdn") {
        player.muted(flag);
      } else if (provider === "vimeo") {
        player.setMuted(flag);
      } else if (provider === "youtube") {
        flag ? player.mute() : player.unMute();
      }
    },
    closePreviewModal() {
      this.sliderPreviewMuteVideo = true;
      this.$bvModal.hide("lightbox" + this.uniqueIndex);
    },
    closePreviewModalOutside() {
      this.sliderPreviewMuteVideo = true;
    },
    setData(data = null) {
      if (data) {
        this.slider.current = data.index;
        this.slider.interval = data.interval;
      }
    },
    miniSliderControl(dir) {
      this.slider.start = false;
      if (dir == "next") {
        this.$refs["miniSlider" + this.uniqueIndex].next();
      } else {
        this.$refs["miniSlider" + this.uniqueIndex].prev();
      }
      this.$emit("sliderControl", {
        index: this.$refs["miniSlider" + this.uniqueIndex].index
      });
    },
    startGallery(isVisible) {
      if (isVisible) {
        this.slider.interval = this.slider.start
          ? this.currentIndex.interval
          : 0;
      }
    },
    showModalGallery(type, items = null, index = null) {
      if (this.currentSlide.type === "video") {
        this.sliderMuteVideo = true;
        this.sliderAudioSettings(
          this.player,
          this.currentSlide.provider,
          this.sliderMuteVideo
        );
      }

      let i = 0;
      if (items) {
        items.forEach((item) => {
          if (index !== null) {
            this.slider.index = index;
          } else {
            if (item.scope == type) {
              this.slider.index = i;
            }
          }
          i++;
        });
      } else {
        this.slider.index = this.slider.current;
      }
      this.$bvModal.show("lightbox" + this.uniqueIndex);
    },
    sliderChangeState() {
      this.currentIndex.interval == 4000 &&
        this.$emit("sliderControl", { index: this.slider.current });
    },
    slideEnd() {
      this.sliderMuteVideo = true;
      const { provider, public_id, type, uniqueId } =
        this.slider.items[this.prevSliderIndex];

      if (type === "video") {
        const ref = "slider_" + provider + public_id + uniqueId;
        const refPreviousSlide = this.usePlayer().players[ref] || this.$refs[ref][0].player.player;
        this.sliderAudioSettings(
          refPreviousSlide,
          provider,
          this.sliderMuteVideo
        );
      }
    },
    async previewSlideEnd() {
      this.sliderPreviewMuteVideo = true;
      const { provider, public_id, type } =
        this.slider.items[this.prevPreviewSliderIndex];
      if (type === "video") {
        const ref = "slider_preview_" + provider + public_id;
        const refPreviousPreviewSlide = this.usePlayer().players[ref] || this.$refs[ref][0].player.player;
        this.sliderAudioSettings(
          refPreviousPreviewSlide,
          provider,
          this.sliderPreviewMuteVideo
        );
      }
    }
  }
};
</script>

<style scoped>
.video-background2 >>> iframe {
  width: 100% !important;
  height: 100% !important;
}

>>> .video-js {
  width: 100%;
  height: 100%;
}

.carousel-image-container {
  max-height: 470px;
}
</style>
