<template>
  <div class="rooms-list">
    <b-row>
      <template v-for="(room, i) in roomsListComputed">
        <b-col
          v-if="offerData(room).length"
          :key="i"
          :cols="offerData(room)[0].media.length ? '12' : '6'"
          :class="[
            'desc-col',
            {
              'mb-5 mt-4': isOfferDataValidComputed(
                selectedVariant.ref,
                room,
                i
              )
            },
            {
              'd-none': !isOfferDataValidComputed(selectedVariant.ref, room, i)
            }
          ]"
        >
          <Offer
            v-if="isOfferDataValidComputed(selectedVariant.ref, room, i)"
            :offer-data="offerData(room)"
            :index="i"
          />
        </b-col>
      </template>
    </b-row>
    <div
      v-for="(room, i) in roomsListComputed"
      :key="room.id"
      class="room-item mt-4"
    >
      <b-card no-body class="ynv-accordion rooms-accordion">
        <b-card-header
          v-b-toggle="selectedVariant.ref + '-room-' + room.id"
          header-tag="div"
          role="tab"
          id="card-header"
          @click="hideDesc"
        >
          <div class="row px-sm-3 px-lg-3 px-md-3 px-lg-0 ynv-accordion-header">
            <div
              class="room-card-header d-flex col-12 col-lg-3 col-md-4 col-sm-12 px-0"
            >
              <div
                v-if="room.media && room.media.length > 0"
                class="room-avatar order-sm-2"
              >
                <b-avatar
                  variant="info"
                  :src="
                    buildMediaURL(
                      room.media[0].public_url,
                      room.media[0].provider,
                      '150x150'
                    )
                  "
                  size="63"
                  class="b-avatar p-sm-1 p-md-0"
                ></b-avatar>
              </div>
              <div
                v-if="room.title"
                class="room-name mr-sm-0 ml-md-2 order-sm-1 order-md-2"
              >
                <div class="d-flex no-wrap acc-label mb-2">
                  {{ $tc("entity.room.rooms", 1) }}
                  <span v-if="rooms.length > 1" class="px-1">
                    {{ i + 1 }}
                  </span>
                  <b-btn
                    v-if="room.room_change"
                    class="ellipsis py-0 room-change-indicator hide-at-collapse-open"
                  >
                    {{ $t("entity.room.room_change_on") }}
                    {{
                      room.to_date && formatDate(room.to_date, room.timezone)
                    }}
                  </b-btn>
                </div>
                <div class="acc-content">
                  {{ room.quantity > 1 ? room.quantity + "x " : ""
                  }}{{ room.title }}
                </div>
              </div>
            </div>

            <div
              class="wrapper-rooms-info m-0 p-0 d-flex justify-content-between flex-md-row open-hide col-12 col-md-7 col-sm-12 col-lg-7"
            >
              <div
                v-if="room.pu_participants_details.length > 0"
                class="room-persons display-none open-hide"
              >
                <div class="acc-label mb-2">
                  {{ $t("entity.room.traveler") }}
                </div>
                <div class="acc-content">
                  {{
                    $tc(
                      "entity.room.persons",
                      room.pu_participants_details.length,
                      { count: room.pu_participants_details.length }
                    )
                  }}
                </div>
              </div>
              <div
                v-if="mealplanData(room)"
                class="room-treatment display-none open-hide"
              >
                <div class="acc-label mb-2">
                  {{ $t("entity.room.treatment") }}
                </div>
                <div class="acc-content">
                  {{
                    mealplanData(room).title == ""
                      ? mealplanData(room).product_code
                        ? $t(
                            "entity.mealplans." +
                              mealplanData(room).product_code.toUpperCase()
                          )
                        : ""
                      : mealplanData(room).title
                  }}
                </div>
              </div>

              <div
                v-if="mealplanData(room)"
                class="room-treatment r-tr-order-header room-ps-display room-treatment-md open-hide py-2 py-sm-1"
              >
                <div class="acc-label d-sm-none">
                  {{ $t("entity.room.traveler") }}/{{
                    $t("entity.room.treatment")
                  }}
                </div>
                <div class="acc-content">
                  <span
                    v-if="
                      room.pu_participants_details.filter(
                        (p) => p.age_code == 'adult'
                      ).length > 0
                    "
                    >{{
                      $tc(
                        "entity.room.adult_pl",
                        room.pu_participants_details.filter(
                          (p) => p.age_code == "adult"
                        ).length,
                        {
                          count: room.pu_participants_details.filter(
                            (p) => p.age_code == "adult"
                          ).length
                        }
                      )
                    }}
                    -
                  </span>
                  <span
                    v-if="
                      room.pu_participants_details.filter(
                        (p) =>
                          p.age_code == 'child' ||
                          p.age_code == 'infant' ||
                          p.age_code == 'teenager'
                      ).length > 0
                    "
                    >{{
                      $tc(
                        "entity.room.child_pl",
                        room.pu_participants_details.filter(
                          (p) =>
                            p.age_code == "child" ||
                            p.age_code == "infant" ||
                            p.age_code == "teenager"
                        ).length,
                        {
                          count: room.pu_participants_details.filter(
                            (p) =>
                              p.age_code == "child" ||
                              p.age_code == "infant" ||
                              p.age_code == "teenager"
                          ).length
                        }
                      )
                    }}
                    -
                  </span>
                  {{
                    mealplanData(room)
                      ? mealplanData(room).title == ""
                        ? $t(
                            "entity.mealplans." +
                              mealplanData(room).product_code.toUpperCase()
                          )
                        : mealplanData(room).title
                      : ""
                  }}
                  <span
                    v-if="mealplanData(room).description"
                    @click="
                      showMealplanDetails(
                        mealplanData(room),
                        room.pu_participants
                      )
                    "
                    class="mdi mdi-information-outline"
                    style="color: #1e5aac; font-size: 20px; cursor: pointer"
                  ></span>
                </div>
              </div>
              <div
                class="room-date open-hide order-sm-5 display-none"
                v-if="selectedVariant.from_date || selectedVariant.to_date"
              >
                <div class="acc-label d-sm-none d-md-inline-block">
                  {{ $t("entity.room.period") }}
                </div>
                <div class="acc-content">
                  {{
                    room.from_date &&
                    $moment(room.from_date).format(
                      $i18n.locale === "it" || $i18n.locale === "en"
                        ? "dd. DD/MM/YYYY"
                        : "dd. DD.MM.YYYY"
                    )
                  }}<br />
                  {{
                    room.to_date &&
                    $moment(room.to_date).format(
                      $i18n.locale === "it" || $i18n.locale === "en"
                        ? "dd. DD/MM/YYYY"
                        : "dd. DD.MM.YYYY"
                    )
                  }}
                </div>
              </div>
              <div
                class="room-date room-ps-display room-date-md py-2 pb-sm-1 mt-sm-2 open-hide d-md-none"
                v-if="room.from_date || room.to_date"
              >
                <b-btn
                  v-if="room.room_change"
                  class="ellipsis my-1 py-0 ml-1 room-change-indicator hide-at-collapse-closed"
                >
                  {{ $t("entity.room.room_change_on") }}
                  {{ room.to_date && formatDate(room.to_date, room.timezone) }}
                </b-btn>
                <div class="acc-label d-sm-none d-md-inline-block">
                  {{ $t("entity.room.period") }}
                </div>
                <div class="acc-content">
                  {{
                    room.from_date && formatDate(room.from_date, room.timezone)
                  }}
                  -
                  {{ room.to_date && formatDate(room.to_date, room.timezone) }}
                </div>
              </div>

              <div
                class="room-price open-hide order-sm-5"
              >
                <div v-if="!!+room.pu_amount">
                  <div class="acc-label d-sm-none d-md-inline-block mb-1">
                    {{ $t("entity.price.price") }}
                  </div>
                  <div class="acc-content py-sm-1">
                    {{ formatPrice(room.pu_amount) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="room-button order-sm-5 col-1 col-md-1 col-sm-2 col-lg-1 d-flex justify-content-end m-0 p-0"
            >
              <div class="acc-content py-2 py-sm-0">
                <b-button
                  class="d-flex accordion-button"
                  pill
                  :class="{
                    square:
                      'radius' in designData &&
                      designData.radius != '' &&
                      parseInt(designData.radius) == 0
                  }"
                >
                  <div class="chevron when-open">
                    <b-icon icon="chevron-up"></b-icon>
                  </div>
                  <div class="chevron when-closed">
                    <b-icon icon="chevron-down"></b-icon>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
          <div
            v-if="room.pu_participants_details.length > 0"
            class="room-mobile-details"
          >
            <div class="d-flex">
              <div v-if="room.pu_participants_details.length > 0">
                {{
                  $tc(
                    "entity.room.persons",
                    room.pu_participants_details.length,
                    { count: room.pu_participants_details.length }
                  )
                }}
              </div>
              <div
                v-if="room.properties.data && room.properties.data.length > 0"
                class="ml-1"
              >
                |
                {{
                  room.properties.data.filter(
                    (x) => x.product_type == "mealplan"
                  ).length > 0
                    ? $t(
                        "entity.mealplans." +
                          room.properties.data
                            .find((p) => p.product_type == "mealplan")
                            .product_code.toUpperCase()
                      )
                    : ""
                }}
              </div>
            </div>
            <div>
              {{ room.from_date && $moment(room.from_date).format("dd. L") }}
              -
              {{ room.to_date && $moment(room.to_date).format("dd. L") }}
            </div>
          </div>
        </b-card-header>
        <b-collapse
          visible
          :id="selectedVariant.ref + '-room-' + room.id"
          :accordion="page.offer_show_rooms === 1 ? 'my-accordion' : ''"
          role="tabpanel"
          @input="collapseToggleKey++"
        >
          <b-card-body tag="div" class="card-body">
            <b-row class="mt-2 row">
              <b-col cols="12" md="6" class="accordion-inner">
                <div
                  class="room-date py-2"
                  v-if="room.from_date || room.to_date"
                >
                  <b-btn
                    v-if="room.room_change"
                    class="ellipsis my-1 py-0 ml-1 room-change-indicator hide-at-collapse-closed"
                  >
                    {{ $t("entity.room.room_change_on") }}
                    {{
                      room.to_date && formatDate(room.to_date, room.timezone)
                    }}
                  </b-btn>
                  <div class="acc-label">{{ $t("entity.room.period") }}</div>
                  <div class="acc-content">
                    {{
                      room.from_date &&
                      formatDate(room.from_date, room.timezone)
                    }}
                    -
                    {{
                      room.to_date && formatDate(room.to_date, room.timezone)
                    }}
                  </div>
                </div>
                <div
                  v-if="mealplanData(room)"
                  class="room-treatment r-tr-order-body py-2"
                >
                  <div class="acc-label">
                    {{ $t("entity.room.traveler") }}/{{
                      $t("entity.room.treatment")
                    }}
                  </div>
                  <div class="acc-content">
                    <span
                      v-if="
                        room.pu_participants_details.filter(
                          (p) => p.age_code == 'adult'
                        ).length > 0
                      "
                      >{{
                        $tc(
                          "entity.room.adult_pl",
                          room.pu_participants_details.filter(
                            (p) => p.age_code == "adult"
                          ).length,
                          {
                            count: room.pu_participants_details.filter(
                              (p) => p.age_code == "adult"
                            ).length
                          }
                        )
                      }}
                      -
                    </span>
                    <span
                      v-if="
                        room.pu_participants_details.filter(
                          (p) =>
                            p.age_code == 'child' ||
                            p.age_code == 'infant' ||
                            p.age_code == 'teenager'
                        ).length > 0
                      "
                      >{{
                        $tc(
                          "entity.room.child_pl",
                          room.pu_participants_details.filter(
                            (p) =>
                              p.age_code == "child" ||
                              p.age_code == "infant" ||
                              p.age_code == "teenager"
                          ).length,
                          {
                            count: room.pu_participants_details.filter(
                              (p) =>
                                p.age_code == "child" ||
                                p.age_code == "infant" ||
                                p.age_code == "teenager"
                            ).length
                          }
                        )
                      }}
                      -
                    </span>
                    {{
                      mealplanData(room)
                        ? mealplanData(room).title == ""
                          ? $t(
                              "entity.mealplans." +
                                mealplanData(room).product_code.toUpperCase()
                            )
                          : mealplanData(room).title
                        : ""
                    }}
                    <span
                      v-if="mealplanData(room).description"
                      @click="
                        showMealplanDetails(
                          mealplanData(room),
                          room.pu_participants
                        )
                      "
                      class="mdi mdi-information-outline"
                      style="color: #1e5aac; font-size: 20px; cursor: pointer"
                    ></span>
                  </div>
                </div>

                <div v-if="room.info" class="room-info py-2">
                  <div class="acc-label mb-1">
                    {{ $t("entity.room.info") }}
                  </div>
                  <div class="acc-content">
                    {{ room.info }}
                  </div>
                </div>

                <div v-if="!!+room.pu_amount" class="room-info py-2">
                  <div class="acc-label">
                    {{ $t("entity.price.price") }}
                  </div>
                  <div class="acc-content">
                    {{ formatPrice(room.pu_amount) }}
                  </div>
                </div>

                <div v-if="room.description" class="room-info py-2">
                  <div class="acc-label mb-2">
                    {{ $t("entity.room.description") }}
                  </div>
                  <TruncateText
                    :key="'key' + collapseToggleKey"
                    v-if="room.description"
                    :content="room.description"
                    :max-length="340"
                    class="room-description"
                  >
                    <template #showMore>
                      <button type="button" class="btn btn-link show-more">
                        Show More
                      </button>
                    </template>
                  </TruncateText>
                </div>
              </b-col>

              <b-col class="d-none d-lg-block" cols="12" md="1"></b-col>
              <b-col cols="12" md="6" lg="5" class="accordion-inner">
                <transition name="fade">
                  <div
                    v-if="room.media && room.media.length > 0"
                    class="room-gallery text-right"
                  >
                    <div
                      class="spinner-border text-secondary gallery-loading"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <rooms-gallery
                      :mediaList="room.media"
                      :roomGalleryStart="roomGalleryStart"
                      :index="i"
                      :room="room"
                    />
                  </div>
                </transition>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
        <div class="accordion-controller">
          <b-button
            class="d-flex"
            v-b-toggle="selectedVariant.ref + '-room-' + room.id"
            pill
          >
            <div class="show-details">
              <div class="button when-closed">
                {{ $t("entity.show_details") }}
              </div>
              <div class="button when-open">
                {{ $t("entity.hide_details") }}
              </div>
              <div class="chevron when-closed">
                <b-icon icon="chevron-down"></b-icon>
              </div>
              <div class="chevron when-open">
                <b-icon icon="chevron-up"></b-icon>
              </div>
            </div>
          </b-button>
        </div>
      </b-card>
    </div>
    <b-modal
      v-if="showModalMealplan"
      id="show-mealplan"
      modal-class="top5px"
      size="xl"
      hide-header
      centered
    >
      <div class="closeButton">
        <button
          class="px-0"
          @click="
            $bvModal.hide('show-mealplan');
            showModalMealplan = null;
          "
        >
          <span class="closeIcon">
            <b-icon icon="x"></b-icon>
          </span>
        </button>
      </div>
      <b-row class="mx-0 ynv-app">
        <b-col
          v-if="showModalMealplan.media.length > 0"
          :md="6"
          cols="12"
          style="padding: 0"
        >
          <mini-slider
            :video-ratio="'4by3'"
            :mediaList="showModalMealplan.media"
            :currentIndex="{ index: 0, interval: 4000 }"
            :uniqueIndex="'mealplan-'"
          ></mini-slider>
        </b-col>
        <b-col
          cols="12"
          :md="showModalMealplan.media.length > 0 ? 6 : 12"
          style="padding: 0 10px"
          class="pt-3 pt-md-0"
        >
          <strong>{{ showModalMealplan.title }}</strong>
          <div
            class="treatment-details custom-table"
            v-html="showModalMealplan.description"
          ></div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Offer from "@/components/blocks/rooms/Offer";
import MiniSlider from "@/components/blocks/MiniSlider";
import TruncateText from "@/components/TruncateText.vue";
import RoomsGallery from "@/components/blocks/rooms/RoomsGallery";
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "Rooms",
  mixins: [lpMixins],
  components: {
    TruncateText,
    RoomsGallery,
    MiniSlider,
    Offer
  },
  props: [
    "page",
    "roomsList",
    "selectedVariant",
    "designData",
    "roomGalleryStart"
  ],
  data() {
    return {
      showModalMealplan: null,
      rooms: [],
      showText: false,
      collapseToggleKey: 0
    };
  },
  mounted() {
    this.rooms = [];
    this.roomsList
      .map((room) => {
        let offerObjectProperty = room.properties.data.find(
          (offer) => offer.product_type == "offer"
        );
        let offerObject =
          (offerObjectProperty &&
            this.selectedVariant.properties.data.find(
              (prop) => prop.key == offerObjectProperty.key
            )) ||
          null;
        return {
          ...room,
          offerID:
            offerObject &&
            "media" in offerObject &&
            offerObject.media.length > 0
              ? offerObject.product_code
              : null,
          offerRef:
            offerObject &&
            "media" in offerObject &&
            offerObject.media.length > 0
              ? offerObject.ref
              : null
        };
      })
      .sort(function (a, b) {
        if (a.offerID < b.offerID) return +1;
        if (a.offerID > b.offerID) return 1;
        return 0;
      });
    this.roomsList.forEach((room) => {
      let offerObjectProperty = room.properties.data.find(
        (offer) => offer.product_type == "offer"
      );
      let offerObject =
        (offerObjectProperty &&
          this.selectedVariant.properties.data.find(
            (prop) => prop.key == offerObjectProperty.key
          )) ||
        null;
      room.offerID =
        offerObject && "media" in offerObject && offerObject.media.length > 0
          ? offerObject.product_code
          : null;
      room.offerRef =
        offerObject && "media" in offerObject && offerObject.media.length > 0
          ? offerObject.ref
          : null;
      this.rooms.push(room);
    });
    this.rooms.sort(function (a, b) {
      if (a.offerID < b.offerID) return +1;
      if (a.offerID > b.offerID) return 1;
      return 0;
    });
  },
  computed: {
    roomsListComputed() {
      return this.roomsList
        .map((room) => {
          let offerObjectProperty = room.properties.data.find(
            (offer) => offer.product_type == "offer"
          );
          let offerObject =
            (offerObjectProperty &&
              this.selectedVariant.properties.data.find(
                (prop) => prop.key == offerObjectProperty.key
              )) ||
            null;
          return {
            ...room,
            offerID: offerObject?.product_code,
            offerRef: offerObject?.ref
          };
        })
        .sort(function (a, b) {
          if (a.offerID < b.offerID) return +1;
          if (a.offerID > b.offerID) return 1;
          return 0;
        });
    }
  },
  methods: {
    offerData(room) {
      let offerPropertyData =
        room.properties.data.find((p) => p.product_type == "offer") || {};
      return this.selectedVariant.properties.data.filter(
        (prop) => prop.key == offerPropertyData.key
      );
    },
    mealplanData(room) {
      let roomPropertyMealplan =
        room.properties.data.find((p) => p.product_type == "mealplan") || {};
      return this.selectedVariant.properties.data.find(
        (prop) => prop.key == roomPropertyMealplan.key
      );
    },
    isOfferDataValidComputed(title, room, i) {
      let offerPropertyData =
        room.properties.data.find((p) => p.product_type === "offer") || {};
      let offerData =
        (offerPropertyData &&
          this.selectedVariant.properties.data.find(
            (prop) => prop.key === offerPropertyData.key
          )) ||
        null;
      return (
        this.roomsListComputed
          .slice(0, i)
          .filter((r) => r.offerID === room.offerID).length === 0 &&
        offerData?.title !== "" &&
        offerData?.description !== ""
      );
    },
    showMealplanDetails(data, participants = 0) {
      new Promise((resolve) => {
        this.showModalMealplan = data;
        this.showModalMealplan.participants = participants;
        resolve(this.showModalMealplan);
      }).then(() => {
        this.$bvModal.show("show-mealplan");
      });
    },
    hideDesc() {
      this.showText = false;
    }
  }
};
</script>

<style scoped>
@media (min-width: 769px) and (max-width: 788px) {
  .room-name {
    padding-top: 0 !important;
  }

  .col-md-7 {
    width: 55%;
    position: absolute;
    right: 85px;
  }

  .room-button {
    width: auto;
    position: absolute;
    right: 20px;
  }

  .room-date .acc-label,
  .room-price .acc-label {
    display: inline-block !important;
  }

  .room-name {
    width: 140px;
  }
}

@media (min-width: 1024px) {
  .room-date {
    min-width: 0;
  }

  .room-name {
    padding-top: 0.15rem;
  }

  div#card-header {
    padding: 14px 40px !important;
  }

  div.card-body {
    padding: 5px 40px 30px 40px !important;
  }

  .acc-label {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .room-card-header {
    padding: 0 8px !important;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .room-card-header .room-avatar {
    order: 2;
    margin-right: 0 !important;
  }

  .room-avatar .b-avatar {
    padding: 4px;
  }

  .room-card-header .room-name {
    margin-right: 0;
    order: 1;
  }

  .acc-content {
    font-size: 15px !important;
  }

  .wrapper-rooms-info {
    flex-direction: column !important;
    width: 100%;
    padding: 10px 8px 0 !important;
    gap: 0;
  }

  .wrapper-rooms-info:before {
    content: "";
    border-top: 1px solid #e5e5e5;
    position: absolute;
    filter: brightness(90%);
    left: -10px;
    right: -10px;
  }

  .wrapper-rooms-info room-treatment,
  .wrapper-rooms-info room-date,
  .room-price .acc-content {
    padding: 4px 0;
  }

  .wrapper-rooms-info .room-price {
    order: 5;
  }

  .wrapper-rooms-info .room-persons {
    order: 3;
  }

  .room-ps-display .acc-label,
  .room-price .acc-label {
    display: none;
  }

  .room-mobile-details {
    display: none !important;
  }

  .room-date,
  .room-treatment {
    display: inline-block !important;
  }

  .display-none {
    display: none !important;
  }

  .r-tr-order-header {
    order: 1;
  }

  .r-tr-order-body {
    order: 0;
  }

  #card-header {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .room-card-header {
    padding: 0;
    justify-content: start;
  }

  .room-card-header .room-avatar {
    order: 1;
  }

  .wrapper-rooms-info {
    flex-direction: row !important;
    gap: 20px;
  }

  .wrapper-rooms-info .room-treatment-md,
  .wrapper-rooms-info .room-date-md {
    display: none !important;
  }

  .wrapper-rooms-info .room-treatment {
    order: 5;
  }

  .wrapper-rooms-info .room-persons {
    order: 3 !important;
  }

  #card-header {
    padding: 14px 15px !important;
  }

  #card-header .acc-content {
    font-size: 15px !important;
  }

  .room-gallery {
    margin-top: 20px !important;
  }

  .room-name {
    padding-top: 0.15rem;
  }
}

@media (min-width: 992px) and (max-width: 999px) {
  #card-header .acc-content {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 992px) {
  .wrapper-rooms-info .room-treatment-md {
    display: none !important;
  }

  .wrapper-rooms-info .room-treatment {
    order: 3;
  }

  .wrapper-rooms-info {
    gap: 20px;
  }

  #card-header {
    padding: 14px 15px !important;
  }

  .room-name {
    padding-top: 0.15rem;
  }
}

@media only screen and (min-width: 790px) {
  .room-gallery {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 455px) {
  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .accordion-inner {
    padding: 0 !important;
  }

  .room-date {
    padding-top: 15px !important;
  }

  #card-header.not-collapsed {
    padding-bottom: 0 !important;
  }
}

.accordion-inner {
  display: flex;
  flex-direction: column;
}

button.show-more {
  color: rgb(30, 90, 172);
  font-size: 15px;
  padding: 0;
}

#card-header .acc-content {
  line-height: 17px;
  padding-bottom: 5px;
}

.ynv-accordion-header {
  height: auto;
  justify-content: space-between !important;
}

.ynv-app .variant-selected .ynv-accordion div.card-body::before {
  left: 0px;
  right: 0px;
}

.variant-selected .ynv-accordion .acc-label {
  margin-top: 0;
}

#card-header .acc-content,
.ynv-accordion .acc-content,
.variant-selected .ynv-accordion .acc-label {
  font-size: 16px;
}

#show-mealplan .closeButton {
  right: 0px;
  top: 4px;
}

@media (max-width: 998px) {
  #show-mealplan .ynv-app {
    position: relative;
    top: 15px;
  }
}

@media only screen and (max-width: 789px) {
  .desc-col {
    padding: 0 16px !important;
  }
}

.room-description {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
}

.room-price {
  min-width: 16%;
}

.desc-col {
  word-break: break-word;
}
</style>